// stylelint-disable declaration-no-important

/* ------------------------------------------------------------------------------
  IMPORTS
*/

@import "jku";

/* ------------------------------------------------------------------------------
  functions
*/

@function rem($px) {
  @return #{$px / 16}rem;
}

/* ------------------------------------------------------------------------------
  COLORS
*/
//rgba(174, 97, 157, 0)
$ASM-LILA: #AE619D;
$ASM-GRAU: #949494;
$ASM-TURKIS: #036E98;

/* ------------------------------------------------------------------------------
  VARIABLES
*/
$left-margin: 4rem;
$right-margin: 2rem;
$top-margin: 1rem;

$map-info-height: 8rem;

/* ------------------------------------------------------------------------------
  DEFAULTS
*/

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* ------------------------------------------------------------------------------
  MAIN
*/

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100%;
  padding: 0;
  width: 100%;
}

.no-scrollbar {
  ::-webkit-scrollbar {
    background: transparent;
    width: 0; /* Remove scrollbar on Chrome/Safari/Webkit */
  }

  body {
    scrollbar-width: none; /* Remove scrollbar on Firefox */
  }
}

main {
  background-color: $JKU-Hellgrau;
  display: block;
  flex: 1 0 auto;
}

button {
  padding: rem(0);
}

/* ------------------------------------------------------------------------------
  ASM - HEADER
*/

header {
  background-color: $JKU-Weiß;
  padding-left: $left-margin;
  padding-right: $right-margin;
  z-index: 8001;
}

#header-logos {
  margin: $top-margin $left-margin;

  a {
    text-decoration: none;
  }
}

/* ------------------------------------------------------------------------------
  ASM - LOGO
*/

#ASM-logo-h2 {
  font-size: rem(12);
  font-weight: 500;
}

/* ------------------------------------------------------------------------------
  ASM - NAV
*/

nav {
  margin-bottom: rem(15);
  margin-left: auto;
  margin-top: rem(15);
}

.nav-ul {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
}

.navbar-nav {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding-left: 0;

  .nav-link {
    align-items: center;
    -webkit-text-fill-color: $JKU-Schwarz;
    -webkit-opacity: 1;
    display: flex;
    height: rem(36);
    padding-left: 0;
    padding-right: 0;
  }

  .nav-item,
  .nav-link {
    text-transform: uppercase;
    margin-left: 0.75rem;

    a {
      text-decoration: none;
    }
  }

  //
  //.nav-item {
  //  padding: 1rem rem(0);
  //}
}

.navbar-x-net .navbar-nav .nav-link:focus,
.navbar-x-net .navbar-nav .nav-link:hover {
  -webkit-text-fill-color: $JKU-Dunkelgrau;
  -webkit-opacity: 1;
  color: $JKU-Dunkelgrau;
}

.navbar-x-net .navbar-nav .show > .nav-link .icon,
.navbar-x-net .navbar-nav .active > .nav-link .icon,
.navbar-x-net .navbar-nav .nav-link.show .icon,
.navbar-x-net .navbar-nav .nav-link.active .icon {
  fill: $JKU-Dunkelgrau;
}

.nav-flex {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navbar-nav {
  align-items: baseline;
}

.ASM-logo {
  align-items: start;
  display: flex;
  flex-direction: column;
}

#navbar .btn-primary a {
  -webkit-text-fill-color: $JKU-Weiß;
  -webkit-opacity: 1;
  color: $JKU-Weiß;
}

#navbar .btn-primary:active a,
#navbar .btn-primary:hover a {
  background-color: $JKU-Weiß;
  -webkit-text-fill-color: $JKU-Schwarz;
  -webkit-opacity: 1;
  color: $JKU-Schwarz;
  cursor: pointer;
}

#navbar {
  background-color: $JKU-Weiß;
  //border-left: rem(1) solid $JKU-Hellgrau;
  //border-bottom: rem(1) solid $JKU-Hellgrau;
  //border-bottom-left-radius: 2rem;
  min-width: 10rem;
  padding: 2rem;
  height: 93vh;

  ul {
    display: flex;
    flex-direction: column;
  }
}

.nav-top-corner-right {
  background-color: $JKU-Weiß;
  position: absolute;
  top: 4.4rem;
  right: -$right-margin;
  width: rem(400);
  z-index: 9999;
}

/* ------------------------------------------------------------------------------
  ASM - MAIN
*/

.btn-main {
  background-color: transparent;
  border-color: $JKU-Weiß;
  border-style: solid;
  border-radius: rem(0);
  -webkit-text-fill-color: $JKU-Weiß;
  -webkit-opacity: 1;
  color: $JKU-Weiß;
  text-transform: uppercase;
}

.asm-tile {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: flex-end;
  align-items: end;
  display: flex;
  height: rem(500);
  width: 100%;

  h1 {
    -webkit-text-fill-color: $JKU-Weiß;
    -webkit-opacity: 1;
    color: $JKU-Weiß;
  }

  p {
    -webkit-text-fill-color: $JKU-Weiß;
    -webkit-opacity: 1;
    color: $JKU-Weiß;
    margin-bottom: 2.2rem;
  }

  button {
    font-size: 120%;
    font-weight: 900;
  }
}

.asm-tile-info {
  margin-bottom: 4.5rem;
  margin-left: $left-margin;
  margin-top: auto;

  h1 {
    margin: rem(0);
  }
}

#asm-tile-1 {
  height: rem(623);
}

#asm-tile-1,
#asm-tile-2 {
  background-color: #344E86;
  background-repeat: no-repeat;
  background-size: cover;

  img {
    margin-left: 0.5rem;
  }
}

#asm-tile-info-2 {
  width: 80%;
}

.asm-tile-3 {
  h1 {
    -webkit-text-fill-color: $ASM-LILA;
    -webkit-opacity: 1;
    color: $ASM-LILA;
  }

  p {
    -webkit-text-fill-color: $ASM-LILA;
    -webkit-opacity: 1;
    color: $ASM-LILA;
  }

  .btn-main {
    background-color: transparent;
    border-color: $ASM-LILA;
    border-style: solid;
    border-radius: rem(0);
    -webkit-text-fill-color: $ASM-LILA;
    -webkit-opacity: 1;
    color: $ASM-LILA;
    text-transform: uppercase;
  }

  .btn-main:active,
  .btn-main:hover {
    background-color: $JKU-Schwarz;
    border-color: $JKU-Schwarz;
    border-style: solid;
    border-radius: rem(0);
    -webkit-text-fill-color: $JKU-Weiß;
    -webkit-opacity: 1;
    color: $JKU-Weiß;
    cursor: pointer;
    padding: rem(8) rem(16);
  }

  .asm-info {
    //position: absolute;
    //left: rem(0);
    //bottom: rem(0);
    margin-bottom: 4.5rem;
    margin-left: $left-margin;
    z-index: 10;
  }
}

#asm-tile-3 {
  display: none;
}

#asm-tile-3-big {
  display: flex;
}

#asm-tile-info-3,
#asm-tile-info-4 {
  margin-bottom: 4.5rem;
  max-width: 50%;
}

#asm-tile-4 {
  background-color: #CFD0DB;
  height: rem(426);

  h1 {
    -webkit-text-fill-color: $ASM-TURKIS;
    -webkit-opacity: 1;
    color: $ASM-TURKIS;
  }

  p {
    -webkit-text-fill-color: $ASM-TURKIS;
    -webkit-opacity: 1;
    color: $ASM-TURKIS;
  }

  .btn-main {
    background-color: transparent;
    border-color: $ASM-TURKIS;
    border-style: solid;
    border-radius: rem(0);
    -webkit-text-fill-color: $ASM-TURKIS;
    -webkit-opacity: 1;
    color: $ASM-TURKIS;
    text-transform: uppercase;
  }

  .btn-main:active,
  .btn-main:hover {
    background-color: $JKU-Schwarz;
    border-color: $JKU-Schwarz;
    border-style: solid;
    border-radius: rem(0);
    -webkit-text-fill-color: $JKU-Weiß;
    -webkit-opacity: 1;
    color: $JKU-Weiß;
    cursor: pointer;
    padding: rem(8) rem(16);
  }

  #main-art {
    background: no-repeat right;
    display: flex;
    height: 100%;
    margin-left: auto;
    width: 50%;

    img {
      margin-left: auto;
      margin-right: 2rem;
      margin-top: 2rem;
    }
  }
}

/* ------------------------------------------------------------------------------
  ASM - Footer
*/

.footer-flex {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 25%;
}

footer {
  background-color: $JKU-Schwarz;
  padding-bottom: rem(24);
  //padding-left: $left-margin;
  padding-right: $right-margin;
  padding-top: rem(48);
}

.footer-right {
  padding-bottom: $top-margin;
  padding-top: $top-margin;
}

/* ------------------------------------------------------------------------------
  ASM/About
*/

.about-main {
  background-color: $JKU-Weiß;
  //margin-left: auto;
  //margin-right: auto;
  //max-width: rem(960);
}

.about-img {
  background-repeat: no-repeat;
  background-size: cover;
  height: rem(500);
  width: 100%;
  height: 20rem;
  //padding-right: 1rem;
}

.about-img-wrapper {
  height: 13rem;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.about-content {
  align-items: center;
  background-color: $JKU-Weiß;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: rem(960);

  p,
  pre {
    margin-left: $right-margin;
    margin-right: $right-margin;
  }
}

.about-header-wrapper {
  margin-top: 2rem;
}

/* ------------------------------------------------------------------------------
  ASM/profile
*/

.profile-container {
  //margin-left: $left-margin;
  margin-right: $right-margin;
  margin-top: $right-margin;
  min-width: 40rem;
  //border-bottom: 0.0625rem solid #e0e1e3;
  //border-radius: 1rem;
  //background-color: $JKU-Weiß;
  padding: $right-margin $right-margin $right-margin $left-margin;
  //margin: 2rem $right-margin 2rem $left-margin;
  //padding: 1rem $right-margin 1rem $left-margin;
}

.profile-header {
  margin-bottom: 2rem;
  //margin-top: 6rem;
}

.profile-institute {
  margin-left: $left-margin;
  margin-right: $right-margin;

  .card {
    border-radius: rem(0);
  }
}

.profile-email {
  align-items: baseline;
  display: flex;
}

#id_email_address {
  padding-left: 1rem;
}

/* ------------------------------------------------------------------------------
  ASM/login
*/

.asm-login-main {
  background-repeat: no-repeat;
  background-size: cover;
}

.login-item-sm {
  max-width: 35rem;
}

.signin-caption {
  display: flex;

  div div:last-child .form-group {
    align-items: end;
    display: flex;
    justify-content: space-between;
  }

  .input-wrapper div div:first-child {
    display: none;
  }
}

.asm-login {
  background-color: $JKU-Weiß;
  margin-left: auto;
  margin-top: 15vh;
  max-width: 100rem;
  width: 80vw;

  .form-control {
    //background-clip: padding-box;
    background-color: $JKU-Weiß;
    //border: var(--x-border-width) solid $JKU-Schwarz;
    border-radius: rem(0);
    box-shadow: none;
    -webkit-text-fill-color: $JKU-Schwarz;
    -webkit-opacity: 1;
    color: $JKU-Schwarz;
    display: block;
    font-size: 100%;
    //height: var(--x-input-height);
    //line-height: var(--x-input-line-height);
    //padding: var(--x-input-padding-y) var(--x-input-padding-x);
    transition: none;
    width: 100%;
  }

  .list-styled {
    //list-style: url("data:image/svg+xml;utf8,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3e%3crect fill='%00000001' height='7' width='7' x='0' y='1'/%3e%3c/svg%3e");
    //margin-left: 0;
    //padding-left: 1.0625rem;
    list-style: inherit;
    font-size: var(--x-small-font-size);
  }

  .asm-login-wrapper {
    padding: 2rem;
    min-height: 35vh;

    input {
      border: rem(3) solid;
    }

    input.is-invalid {
      border-color: var(--x-danger);
    }
  }

  legend {
    -webkit-text-fill-color: #036E98;
    -webkit-opacity: 1;
    color: #036E98;
    font-size: 120%;
  }
}

.asm-register {
  -webkit-text-fill-color: $JKU-Weiß;
  -webkit-opacity: 1;
  color: $JKU-Weiß;
  margin-left: 4rem;
  margin-top: 3rem;

  h1 {
    -webkit-text-fill-color: $JKU-Weiß;
    -webkit-opacity: 1;
    color: $JKU-Weiß;
    margin: rem(0);
  }

  .card-body {
    border: none;
  }

  .card {
    border: none;
  }
}


/* ------------------------------------------------------------------------------
  ASM - MAP
*/

.top-corner-right {
  background-color: $JKU-Weiß;
  position: fixed;
  top: 7.5rem;
  right: 2.5rem;
  z-index: 9999;
}

.top-corner-right:hover {
  cursor: pointer;
}

.map-filter-close {
  font-size: 150%;
  margin-bottom: 0.5rem;
  text-align: right;
}

.map-filter-close {
  cursor: pointer;
}

.map-filter-btn {
  border-color: $JKU-Schwarz;
  border-style: solid;
  border-radius: rem(2);
  height: 2.2rem;
  margin: rem(0);
  padding: rem(4);
  text-align: center;
  width: 5rem;
  z-index: 8000;
}

.map-filter {
  box-shadow: rem(0) rem(4) rem(12) rem(0) #00000040;
  flex-direction: column;
  max-height: 90vh;
  position: fixed;
  overflow-y: scroll;
  //top: rem(70);
  right: rem(0);
  z-index: 8000;
}

#map-filter {
  background-color: $JKU-Weiß;
  padding: 1rem;
  width: 25rem;

  .map-filter-summary {
    -webkit-text-fill-color: #036E98;
    -webkit-opacity: 1;
    color: #036E98;
    cursor: pointer;
    justify-content: center;
    margin-bottom: 0.5rem;
    padding: rem(0);
    position: relative;
  }

  .map-filter-summary::after {
    align-items: center;
    -webkit-text-fill-color: $JKU-Schwarz;
    -webkit-opacity: 1;
    color: $JKU-Schwarz;
    content: "▼";
    display: inline-flex;
    flex-shrink: 0;
    height: 1.75rem;
    justify-content: center;
    position: absolute;
    right: rem(0);
    top: 50%;
    transform: translateY(-50%);
    width: 1.75rem;
  }

  details[open] summary::after {
    content: "▲";
  }

  .map-filter-summary:hover {
    cursor: pointer;
  }

  form > details > summary {
    list-style: none;
  }

  .map-filter-summary::-webkit-details-marker {
    display: none;
  }
}

.map-filter-group {
  legend {
    -webkit-text-fill-color: $JKU-Blau;
    -webkit-opacity: 1;
    color: $JKU-Blau;
  }

  &.study-programms {
    .form-group {
      margin: 0;
    }
  }
}

.asm-card-box {
  justify-content: space-between;
}

.teaser {
  max-height: 10rem;
  overflow: hidden;
}

.teaser-img {
  max-height: 16.875rem;
  max-width: 30rem;
  overflow: hidden;
}

.teaser-img-detail {
  height: 6rem;
  max-width: 100%;
}

.profile-main {
  padding-bottom: 2rem;
}

#institute_map {
  height: 70vh;
}

#map-filter-x {
  font-weight: bold;
}

.institutes-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: $top-margin $left-margin;
  max-width: 100rem;
  margin-left: auto;
  margin-right: auto;

  .row {
    padding: rem(0);
  }

  .card {
    border: none;
  }
}

.card-img-placeholder {
  //background-color: $JKU-Hellgrau;
  background-color: #F9F9F9;
  //width: 100%;
  //height: rem(270);
  height: 16.875rem;
  width: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    display: flex;
    justify-content: center;
  }
}

.map-main {
  background-color: $JKU-Weiß;
}

.map-info-container {
  display: flex;
  flex-direction: row;
  //justify-content: space-around;
  width: 100%;
}

.map-info-tile {
  align-items: center;
  background: rgba(3, 110, 152, 0.42);
  display: flex;
  justify-content: space-around;
  height: $map-info-height;
  margin-left: rem(2);
  margin-right: rem(2);
  margin-top: -$map-info-height;
  width: 24.9%;
  z-index: 7999;
}

.map-info-tile-count {
  -webkit-text-fill-color: $JKU-Weiß;
  -webkit-opacity: 1;
  color: $JKU-Weiß;
  font-size: rem(30);
  padding-bottom: 1rem;
}

.map-info-tile-title {
  -webkit-text-fill-color: $JKU-Weiß;
  -webkit-opacity: 1;
  color: $JKU-Weiß;
  font-size: rem(20);
}

.card {
  margin-bottom: 1rem;
}

.map-filter-group-study {
  .form-group {
    margin: rem(0);
  }
}

/* ------------------------------------------------------------------------------
  ASM - Profile
*/

.institutes-container-profile {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.institutes-name {
  margin-bottom: rem(0);
}

.institutes-name:hover {
  cursor: pointer;
}

.institute-img {
  max-height: 25rem;
  max-width: 100%;
}

/* ------------------------------------------------------------------------------
  ASM - FAQ
*/

.faq-img {
  background-repeat: no-repeat;
  background-size: cover;
  max-height: rem(200);
  width: 100%;
}

.faq-content {
  margin-bottom: 5rem;
  margin-top: 5rem;
  min-width: 40rem;

  h4 {
    margin-bottom: rem(0);
  }

  .middle {
    padding-left: 25%;
    padding-right: 25%;
  }

  summary {
    cursor: pointer;
    margin-bottom: 0.5rem;
    padding: 1rem rem(0);
    position: relative;
  }

  summary::after {
    align-items: center;
    -webkit-text-fill-color: $JKU-Schwarz;
    -webkit-opacity: 1;
    color: $JKU-Schwarz;
    //content: "▼";
    content: "+";
    display: inline-flex;
    flex-shrink: 0;
    height: 1.75rem;
    justify-content: center;
    position: absolute;
    right: 20%;
    top: 50%;
    transform: translateY(-50%);
    width: 1.75rem;
    font-size: 160%;
    font-weight: bolder;
  }

  details summary {
    background-color: #F9F9F9;
  }

  details[open] summary {
    background-color: $ASM-LILA;

    h4 {
      -webkit-text-fill-color: $JKU-Weiß;
      -webkit-opacity: 1;
      color: $JKU-Weiß;
    }
  }

  details[open] summary::after {
    //content: "▲";
    content: "-";
    -webkit-text-fill-color: $JKU-Weiß;
    -webkit-opacity: 1;
    color: $JKU-Weiß;
    font-size: 160%;
    font-weight: bolder;
  }

  summary:hover {
    cursor: pointer;
  }

  details > summary {
    list-style: none;
    width: 100%;
  }

  details {
    width: 100%;
  }

  summary::-webkit-details-marker {
    display: none;
  }

  .faq-item {
    display: flex;
  }
}

/* ------------------------------------------------------------------------------
  ASM - Contact
*/

.contact-container {
  max-width: rem(960);
  padding: 2rem;
  background-color: $JKU-Weiß;
  border-radius: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  //
  //input {
  //  max-width: 68%;
  //}
}

.contact-main {
  background-color: $JKU-Weiß;
  //margin-left: auto;
  //margin-right: auto;
  //max-width: 60rem;
}

.contact-img {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  //padding-right: 1rem;
}

/* ------------------------------------------------------------------------------
  ASM - Institute
*/

.institute-content {
  border-bottom: 0.0625rem solid #e0e1e3;
  //border-radius: 1rem;
  margin: 2rem $right-margin 2rem $left-margin;
  padding: 1rem $right-margin 1rem $left-margin;
}

.JKU-main {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: rem(960);

  .alert {
    border-radius: rem(0);
  }

  input,
  select,
  textarea {
    border: rem(3) solid $JKU-Schwarz;
  }
}

.institute-topics {
  display: flex;

  h4 {
    min-width: 12rem;
  }
}

.youtube-player {
  max-width: 100%;
}

.form-control {
  background-color: $JKU-Weiß;
  border: var(--x-border-width) solid $JKU-Schwarz;
  border-radius: rem(0);
  box-shadow: none;
  -webkit-text-fill-color: $JKU-Schwarz;
  -webkit-opacity: 1;
  color: $JKU-Schwarz;
  display: block;
  font-size: 100%;
  transition: none;
  width: 100%;
}

.custom-select {
  -webkit-appearance: none;
  appearance: none;
  background-color: $JKU-Weiß;
  border: var(--x-border-width) solid $JKU-Schwarz;
  border-radius: rem(0);
  -webkit-text-fill-color: $JKU-Schwarz;
  -webkit-opacity: 1;
  color: $JKU-Schwarz;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  //font-size: var(--x-input-font-size);
  font-size: 100%;
  text-transform: none;
  vertical-align: middle;
  width: 100%;
  word-wrap: normal;
}

.asm-pw-change {
  .modal-content {
    border: none;
    border-radius: rem(0);
  }

  .list-styled {
    list-style: inherit;
  }
}

.asm-icon-dark-light {
  fill: $JKU-Weiß;
  //height: 5rem;
  //width: 5rem;
}

#asm-icon-dark-light-btn:hover {
  .asm-icon-dark-light {
    fill: $JKU-Schwarz;
  }
}

.asm-fomula {
  .form-group label,
  label {
    //.JKU-H4
    //font-size: rem(16);
    //line-height: rem(24);
    //.JKU-H3
    font-size: rem(18);
    line-height: rem(24);
    font-weight: bold;
  }

  .input-wrapper {
    display: flex;
    flex-wrap: wrap;

    label {
      margin-right: 1rem;
    }
  }

  .form-text {
    -webkit-text-fill-color: $JKU-Schwarz;
    -webkit-opacity: 1;
    color: $JKU-Schwarz;
    font-size: rem(12);
    line-height: rem(20);

    a {
      font-size: rem(12);
      line-height: rem(20);
    }

    a:hover {
      -webkit-text-fill-color: $JKU-Schwarz;
      -webkit-opacity: 1;
      color: $JKU-Schwarz;
      cursor: pointer;
    }
  }
}

#invitation {
  border: rem(3) dashed $JKU-Schwarz;
  background-color: $JKU-Weiß;
}

.contact-container {
  input,
  select,
  textarea {
    border: rem(3) solid $JKU-Schwarz;
  }
}

.custom-checkbox .custom-control-label::before {
  border-radius: rem(0);

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $JKU-Weiß;
  }
}

.modal-body {
  .institute-content {
    margin: rem(0) $right-margin rem(0) $left-margin;
    padding: 1rem $right-margin 1rem $left-margin;
    border-bottom: none;
  }
}

.fade-asm {
  transition: opacity 1s linear;
}

pre {
  max-width: 100%;
  overflow: auto;
  white-space: pre-wrap;
}

#logo-footer-wrapper {
  margin-left: $left-margin;
  margin-right: 2rem;
  height: 6rem;
  width: 12rem;
  box-sizing: border-box;

  #logo-footer {
    height: 5rem;
    width: 11rem;
  }

  button {
    border: none;
    padding: rem(0);
  }

  button:hover {
    //border: $JKU-Weiß;
    //border-style: solid;
    //border-radius: rem(0);
    //box-shadow: inset 0 0 0 rem(2) $JKU-Weiß;
  }
}

.footer-link {
  a {
    margin: rem(0) rem(0) rem(24) rem(32);
  }
}

.step-header-link {
  font-size: 100%;
}

.step-header-link:hover {
  color: $JKU-Schwarz;
}

.specifics-set {
  //border-top: rem(2) solid $JKU-Schwarz;
  padding-top: 1rem;

  legend {
    border-bottom: rem(2) solid $JKU-Schwarz;
    font-size: rem(24);
    line-height: rem(32);
    font-weight: 700;
  }
}

#institute_map_main {
  height: 31.25rem;
}

.faq-main {
  background-color: white;
  //margin-left: auto;
  //margin-right: auto;
  //width: 60rem;
}

.edit-topic {
  flex-direction: row;

  #detail-topic-art {
    margin-bottom: 1.5rem;
  }

  #detail-topic-science {
    margin-left: 3rem;
  }

  .inactive-topic {
    color: $JKU-Mittelgrau;
  }
}

#video-info {
  width: rem(560);
  height: rem(315);
  display: flex;
}


.degree-container {
  legend {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  a:hover {
    color: $JKU-Dunkelgrau;
  }
}

.edit-btns {
  flex-direction: row;
}

.save-btn-style {
  margin-left: auto;
}

.data-privacy-statment {
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }

  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }

  ol > li::before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
  }

  li ol > li {
    margin: 0;
  }

  li ol > li::before {
    content: counters(item, ".") " ";
  }
}

@import "mobile";
