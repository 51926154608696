// stylelint-disable declaration-no-important

/* ------------------------------------------------------------------------------
  functions
*/

@function rem($px) {
  @return #{$px / 16}rem;
}

/* ------------------------------------------------------------------------------
  JKU Fonts
*/
//
//@font-face {
//  font-family: "JKU-Gotham-Book";
//  font-weight: normal;
//  font-style: normal;
//  src: url("./asm/static/fonts/GothamBook.ttf") format('truetype');
//}
//
//@font-face {
//  font-family: "JKU-Gotham-Bold";
//  src: url("./asm/static/fonts/GothamBold.ttf") format('truetype');
//  font-weight: normal;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: "JKU-Gotham-Black";
//  src: url("./asm/static/fonts/Gotham-Black.otf") format('OpenType');
//  font-weight: normal;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: JKU-Gotham-Bold;
//  src: url("./asm/static/fonts/Gotham-Bold.otf") format('OpenType');
//  font-weight: normal;
//  font-style: normal;
//}

/* ------------------------------------------------------------------------------
  JKU Typographie Desktop
*/

.JKU-Title,
title {
  font-size: rem(64);
  line-height: rem(72);
}

.JKU-H1,
h1 {
  font-size: rem(48);
  line-height: rem(64);
}

.JKU-H2,
h2 {
  font-size: rem(32);
  line-height: rem(32);
}

.JKU-H3,
h3 {
  font-size: rem(24);
  line-height: rem(32);
}

.JKU-H4,
h4,
a {
  font-size: rem(18);
  line-height: rem(24);
}

.JKU-H5,
h5 {
  font-size: rem(16);
  line-height: rem(24);
}

.JKU-Labels {
  font-size: rem(16);
  line-height: rem(24);
  text-transform: uppercase;
}

.JKU-Introtext {
  font-size: rem(24);
  line-height: rem(32);
}

.JKU-Paragraph,
p,
ul,
ol {
  font-size: rem(18);
  line-height: rem(24);
}

.JKU-Paragraph-Small {
  font-size: rem(16);
  line-height: rem(24);
}

///* ------------------------------------------------------------------------------
//  JKU Typographie Mobile
//*/
//
@media only screen and (max-width: rem(700)) {
  .JKU-Title {
    font-size: rem(32);
    line-height: rem(32);
  }

  .JKU-H1 {
    font-size: rem(32);
    line-height: rem(32);
  }

  .JKU-H2 {
    font-size: rem(24);
    line-height: rem(32);
  }

  .JKU-H3 {
    font-size: rem(18);
    line-height: rem(24);
  }

  .JKU-H4 {
    font-size: rem(16);
    line-height: rem(24);
  }

  .JKU-H5 {
    font-size: rem(16);
    line-height: rem(24);
  }

  .JKU-Labels {
    font-size: rem(14);
    line-height: rem(20);
    text-transform: uppercase;
  }

  .JKU-Introtext {
    font-size: rem(18);
    line-height: rem(24);
  }

  .JKU-Paragraph {
    font-size: rem(16);
    line-height: rem(24);
  }

  .JKU-Paragraph-Small {
    font-size: rem(16);
    line-height: rem(24);
  }
}

/* ------------------------------------------------------------------------------
  JKU COLORS
*/

// JKU Standardfarbe
$JKU-Schwarz: #000;
// JKU Standardfarbe
$JKU-Weiß: #fff;
// Business School
// Studienrichtungen, die dem Bereich Wirtschaft zuzuordnen sind
$JKU-Hellgrün: #bfce52;
// Sozial- und Wirtschafts-wissenschaftliche Fakultät
// Studienrichtungen, die dem Bereich Gesellschaft zuzuordnen sind
$JKU-Grün: #5ba775;
// diese Farbe wurde noch keiner Fakultät, School oder Studienrichtung zugewiesen
$JKU-Hellblau: #4fb0bf;
// Linz Institute of Technology
// Technisch-Natur-wissenschaftliche Fakultät
// Studienrichtungen, die dem Bereich Technik und Natur- wissenschaften zuzuordnen sind
$JKU-Blau: #0084bb;
// Medizinische Fakultät
// Studienrichtungen, die dem Bereich Medizin zuzuordnen sind
$JKU-Gelb: #f1bc3f;
// Rechtswissen-schaftliche Fakultät
// Studienrichtungen, die dem Bereich Recht zuzuordnen sind
$JKU-Rot: #d95c4c;
// Linz School of Education
// Studienrichtungen, die dem Bereich Pädagogik zuzuordnen sind
$JKU-Lila: #ae619d;
// Zentrale Dienste
// Einsatz im Web zudem als Hover und Active State
$JKU-Dunkelgrau: #7d828c;
// Einsatz im Web als Hintergrund
$JKU-Mittelgrau: #b1b4ba;
// Einsatz im Web als Hintergrund
$JKU-Hellgrau: #e0e1e3;

/* ------------------------------------------------------------------------------
  JKU Buttons und Links
*/

.btn-primary {
  background-color: $JKU-Schwarz;
  border-color: $JKU-Schwarz;
  border-style: solid;
  border-radius: rem(0);
  color: $JKU-Weiß;
  padding: rem(8) rem(16);
  text-transform: uppercase;
}

.btn-primary:active,
.btn-primary:hover {
  background-color: $JKU-Weiß;
  border-color: $JKU-Schwarz;
  border-style: solid;
  border-radius: rem(0);
  color: $JKU-Schwarz;
  cursor: pointer;
  padding: rem(8) rem(16);
  text-transform: uppercase;
}

.btn-secondary {
  background-color: $JKU-Weiß;
  border-color: $JKU-Schwarz;
  border-style: solid;
  border-radius: rem(0);
  color: $JKU-Schwarz;
  padding: rem(8) rem(16);
  text-transform: uppercase;
  text-align: -webkit-center;
  text-align: center;
}

.btn-secondary:active,
.btn-secondary:hover {
  background-color: $JKU-Schwarz;
  border-color: $JKU-Schwarz;
  border-style: solid;
  border-radius: rem(0);
  color: $JKU-Weiß;
  cursor: pointer;
  padding: rem(8) rem(16);
  text-transform: uppercase;
}

a {
  color: $JKU-Schwarz;
}

a:hover {
  color: $JKU-Hellgrau;
  cursor: pointer;
}
