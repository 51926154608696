// stylelint-disable declaration-no-important
/* stylelint-disable */
@media only screen and (max-width: 1023px) {
  /* stylelint-enable */
  .nav-item {
    margin-left: rem(0);
    margin-right: 0.75rem;
  }

  #asm-tile-4 {
    #main-art {
      display: none;
    }
  }

  .about-img {
    height: 40vh;
  }

  .institutes-container {
    padding: 1rem;
    //margin-left: 1rem;
  }

  .modal-body {
    .institute-content {
      margin: rem(0) rem(0) rem(0) rem(0);
      padding: 1rem $right-margin 1rem $left-margin;
      border-bottom: none;
    }
  }

  #asm-tile-3 {
    display: flex;
  }

  #asm-tile-3-big {
    display: none;
  }
}

/* stylelint-disable */
@media only screen and (max-width: 990px) {
  /* stylelint-enable */
  .asm-card-box {
    justify-content: start;
  }
}

/* stylelint-disable */
@media only screen and (max-width: 800px) {
  /* stylelint-enable */
  .faq-content {
    min-width: inherit;

    .middle {
      padding-left: $left-margin;
      padding-right: $left-margin;
    }
  }

  .profile-container {
    min-width: inherit;
  }
}

/* stylelint-disable */
@media only screen and (max-width: 700px) {
  /* stylelint-enable */
  .nav-top-corner-right {
    top: 4.2rem;
  }

  .map-filter {
    //max-height: 90vh;
    //overflow-y: scroll;
    top: 5rem;
  }

  .asm-login {
    background-color: $JKU-Weiß;
    margin-left: rem(0);
    margin-top: 4rem;
    width: 100%;
  }

  .modal-body {
    .institute-content {
      margin: rem(0) rem(0) rem(0) rem(0);
      padding: rem(0) rem(0) rem(0) rem(0);
      border-bottom: none;
    }
  }

  .profile-container {
    padding: $right-margin rem(0);
  }

  .edit-btns {
    flex-direction: column;

    .btn {
      margin-top: 0.5rem;
    }

    .save-btn-style {
      margin-left: rem(0);
    }
  }
}

/* stylelint-disable */
@media only screen and (max-width: 550px) {
  /* stylelint-enable */
  header {
    padding-left: $right-margin;

    #ASM-logo-h2 {
      display: none;
    }
  }

  #asm-tile-1,
  .asm-tile {
    max-height: 25rem;
  }

  .asm-tile-info {
    margin-left: $right-margin;
  }

  #asm-tile-info-3,
  #asm-tile-info-4 {
    margin-bottom: 2rem;
    max-width: 100%;
  }

  .asm-tile-3 {
    .asm-info {
      margin-left: $right-margin;
    }
  }

  footer {
    padding-left: $right-margin;
    padding-right: $right-margin;
  }

  .nav-top-corner-right {
    top: 3.15rem;
  }

  #navbar {
    border-bottom-left-radius: 0.5rem;
    padding: 0.5rem;
  }

  .nav-top-corner-right {
    width: inherit;
    z-index: 9999;
  }

  .faq-content {
    min-width: inherit;

    .middle {
      padding-left: $right-margin;
      padding-right: $right-margin;

      h4 {
        max-width: 80vw;
      }
    }
  }

  .faq-content summary::after {
    right: $right-margin;
  }

  .profile-container {
    margin-left: $right-margin;
  }

  .profile-institute {
    margin-left: $right-margin;
    margin-right: $right-margin;
  }

  .institute-content {
    margin: 0.5rem;
    padding: 0.5rem;
  }

  .institute-topics {
    min-width: inherit;
    flex-direction: column;
  }

  .map-info-tile-title {
    font-size: 80%;
  }

  .signin-caption {
    display: flex;

    div div:last-child .form-group {
      align-items: center;
      flex-direction: column;

      input {
        margin-top: 0.5rem;
      }
    }
  }

  .map-filter {
    max-height: 90vh;
    overflow-y: scroll;
    position: fixed;
    top: 4rem;
    right: 1rem;
    z-index: 8000;
  }

  .asm-register {
    margin-left: $right-margin;
  }

  #logo-footer-wrapper {
    margin-left: rem(0);
  }

  .footer-left {
    display: flex;
    flex-direction: column;
  }

  .footer-text {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 1rem;
  }

  .footer-link {
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  #map-filter {
    width: unset;
  }

  .edit-topic {
    flex-direction: column;

    #detail-topic-art {
      margin: rem(0);
    }

    #detail-topic-science {
      margin: rem(0);
    }
  }

  //.teaser-img {
  //  height: 10.55rem;
  //  width: 18.75rem;
  //}
  //
  .card-img-placeholder {
    height: 10.55rem;
    width: 18.75rem;
  }
}

/* stylelint-disable */
@media only screen and (max-width: 350px) {
  /* stylelint-enable */
  .profile-email {
    align-items: baseline;
    display: flex;
    flex-direction: column;

    #id_email_address {
      padding-left: rem(0);
      padding-top: 1rem;
    }
  }

  .map-filter {
    position: fixed;
    top: inherit;
    right: inherit;
    z-index: 8000;
  }

  header {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .asm-login {
    .asm-login-wrapper {
      padding: 0.5rem;
    }
  }

  .teaser-img {
    height: 8.79rem;
    width: 15.63rem;
  }

  .card-img-placeholder {
    height: 8.79rem;
    width: 15.63rem;
  }
}

